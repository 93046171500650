<template>
    <div class="view">
        <el-tabs class="mt20 pl20 pr20" v-model="shopTableInfo.status" @tab-click="tabClickFun">
            <el-tab-pane :label="name" :name="id" v-for="(name, id) in statusObj" :key="id">
            </el-tab-pane>
        </el-tabs>
        <div class="pa24">
            <div class="d-flex">
                <div>
                    <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="shopTableInfo.searchName" />
                    <el-button type="primary" @click="shopSearchFun">搜索</el-button>
                </div>
                <div style="margin-left: auto">
                    <el-button class="ma mr10" type="primary" @click="showImportFun">导入商家</el-button>
                    <el-button class="ma mr10" type="primary" @click="showEnterTypeModel">设置进驻分类
                    </el-button>
                    <el-button class="ma mr10" type="primary" @click="showOptionModelFun">设置进驻费用
                    </el-button>
                </div>
            </div>

            <commonTable :tableData="shopTableInfo.tableData" :loading="shopTableInfo.loading"
                @handleSizeChange="shopSizeChange" @handleCurrentChange="shopCurrentChange"
                :currentPage="shopTableInfo.currentPage" :total="shopTableInfo.total">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="typeTitle" align="center" label="进驻栏目" show-overflow-tooltip />
                    <el-table-column prop="companyName" align="center" label="公司名称" show-overflow-tooltip />
                    <el-table-column prop="name" align="center" label="联系人" show-overflow-tooltip />
                    <el-table-column prop="phone" align="center" label="联系方式" show-overflow-tooltip />
                    <el-table-column prop="startTime" align="center" label="进驻时间" show-overflow-tooltip />
                    <el-table-column prop="endTime" align="center" label="到期时间" show-overflow-tooltip />
                    <el-table-column align="center" label="操作" width="130">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showDetailsModelFun(scope.row)">详 情</el-button>
                            <el-button type="text" style="color:#F56C6C;" v-if="scope.row.status == 3"
                                @click="showReasonModelFun(scope.row.intoId)">禁 用</el-button>
                            <el-button type="text" v-if="scope.row.status == 5"
                                @click="examineFun('3', scope.row.intoId)">恢复正常</el-button>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="详情信息"
            :visible.sync="showShopApplyModel" :destroy-on-close="true" width="500px" center>
            <el-form :model="shopApplyDetails" ref="shopApplyDetails" label-width="150px" v-loading="shopDetailsLoading">
                <el-form-item label="公司名称：">
                    <div>{{ shopApplyDetails.companyName }}</div>
                </el-form-item>
                <el-form-item label="联系人：">
                    <div>{{ shopApplyDetails.name }}</div>
                </el-form-item>
                <el-form-item label="联系电话：">
                    <div>{{ shopApplyDetails.phone }}</div>
                </el-form-item>
                <el-form-item label="代理类型：">
                    <el-tag class="mr5" v-for="(row, index) in shopApplyDetails.typeList" size="medium" :key="index">
                        {{ row.typeName }}</el-tag>
                </el-form-item>
                <el-form-item label="进驻时间：">
                    <div>{{ shopApplyDetails.startTime }}</div>
                </el-form-item>
                <el-form-item label="到期时间：">
                    <div>{{ shopApplyDetails.endTime }}</div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="禁用原因" :visible.sync="showReasonModel"
            :destroy-on-close="true" width="450px" center>
            <el-input type="textarea" :rows="5" placeholder="请输入禁用原因" v-model="reason">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showReasonModel = false">取 消</el-button>
                <el-button type="primary" @click="examineFun('5', updateId)">确 认</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="showImgTitle" :visible.sync="showImg"
            :destroy-on-close="true" center>
            <el-carousel :autoplay="false" height="500px">
                <el-carousel-item v-for="item in imgUrlArr" :key="item">
                    <!-- <img height="100%" width="100%" :src="item" alt=""> -->
                    <el-image style="width: 100%; height: 100%" :src="item" fit="scale-down"></el-image>
                </el-carousel-item>
            </el-carousel>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="设置进驻费用"
            :visible.sync="showOptionModel" :destroy-on-close="true" width="400px" center>
            <el-form :model="optionData" ref="optionData" :rules="optionRules" label-width="150px"
                v-loading="optionLoading">
                <el-form-item label="进驻费用：" prop="intoPrice">
                    <el-input class="suffixInput" type="number" placeholder="请输入进驻费用" v-model="optionData.intoPrice">
                        <template slot="append"> / 年</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="可选代理分类数量：" prop="typeNum">
                    <el-input placeholder="请输入数量" type="number" v-model="optionData.typeNum">
                    </el-input>
                </el-form-item>
                <el-form-item label="是否审核进驻：">
                    <el-switch v-model="optionData.auditInto" active-text="审核" inactive-text="不用审核">
                    </el-switch>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="showOptionModel = false">取 消</el-button>
                <el-button type="primary" @click="setOptionInfo">保 存</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="设置进驻分类" :visible.sync="showEnterType"
            :destroy-on-close="true">
            <commonTable :tableData="typeData" :loading="typeDataLoading" :paginationVisible="false">
                <template v-slot:table>
                    <el-table-column prop="iconUrl" label="分类图标" align="center" width="150">
                        <template slot-scope="scope">
                            <img :src="scope.row.iconUrl" width="50px" height="50px" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" align="center" label="分类名称" show-overflow-tooltip />
                    <el-table-column align="center" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value="0" active-text="启用" inactive-text="关闭"
                                :inactive-value="1" @change="changeEnterTypeStatus($event, scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEnterType = false" type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="导入商家" :visible.sync="showImportModel"
            :destroy-on-close="true" width="500px" center>
            <el-form :model="uploadFromData" ref="uploadFromData" :rules="uploadRules" label-width="150px">
                <el-form-item label="进驻类型：" prop="typeLink">
                    <div v-if="importTypeData.length > 0">
                        <el-checkbox-group v-model="uploadFromData.typeLink" @change="changeImportType">
                            <el-checkbox :label="item.linkId" v-for="item in importTypeData" :key="item.linkId">{{
                                item.typeName
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div v-else>
                        请开启进驻分类
                    </div>
                    <!-- <el-select v-model="uploadData.typeLink" multiple placeholder="请选择">
                        <el-option v-for="item in importTypeData" :key="item.typeId" :label="item.typeName"
                            :value="item.typeId">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="导入文件：">
                    <el-upload class="importLoad" :before-upload="beforeUploadFun"
                        :disabled="uploadFromData.typeLink.length >= 3 || uploadFromData.typeLink.length == 0"
                        :headers="headers" :action="uploadUrl" :on-success="handleSuccess" :data="uploadData">
                        <el-button :disabled="uploadFromData.typeLink.length >= 3 || uploadFromData.typeLink.length == 0"
                            size="small" type="primary">点击上传，导入商家信息</el-button>
                        <div slot="tip" class="el-upload__tip">选择三个进驻类型并上传excel文件，导入商家信息</div>
                    </el-upload>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="导入提示" :visible.sync="showMessage"
            :destroy-on-close="true" width="300px" center>
            <div>{{ importMessage }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMessage = false" type="primary">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    getParkIntoCompanyList,
    getParkIntoCompany,
    updateParkIntoCompany,
    //设置费用
    setParkIntoOption,
    getParkIntoOption,
    //进驻分类
    updateParkShopTypeLink,
    insertParkShopTypeLink,
    getParkShopTypeLinkList
} from "@/api/association"
import commonTable from "@/components/common/commonTable";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "propertyTable",
    components: {
        commonTable
    },
    data() {
        return {
            //进驻费用设置
            optionData: {
                intoPrice: "",
                typeNum: 0,
                auditInto:true
            },
            optionRules: {
                intoPrice: [{ required: true, message: "请输入进驻费用", trigger: "blur" }],
                typeNum: [{ required: true, message: "请输入数量", trigger: "blur" }, {
                    message: "数量在 1 到 20", trigger: "blur", validator(rule, value, callback) {
                        let n = Number(value);
                        if (n > 0 && n <= 20) {
                            callback()
                        } else {
                            callback(new Error("数量在 1 到 20"))
                        }
                    }
                }]
            },
            showOptionModel: false,
            //新增/修改设置
            showImg: false,
            imgUrlArr: "",
            showImgTitle: "",
            productType: "discount",
            showReasonModel: false,//不通过原因模态框显示状态
            reason: "",//不通过原因
            showShopApplyModel: false,//进驻商家模态框显示状态
            shopDetailsLoading: false,//进驻商家模态框加载状态
            optionLoading: false,
            shopApplyDetails: {//进驻商家详情
                searchName: "",
                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                total: 0, //总条数
                tableData: [],
                loading: false, //表格加载
                status: "3",
                startTime: "",
                endTime: ""
            },

            discountTypeObj: {
                0: "全店商品",
                1: "部分商品"
            },
            updateId: 0,
            //列表设置
            intoType: "0",
            associationId: 0,
            //周边商品设置
            shopTableInfo: {
                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                total: 0, //总条数
                tableData: [],
                loading: false, //表格加载
                searchName: "",
                status: "3",
                startTime: "",
                endTime: ""
            },
            statusObj: {//审核状态
                3: "正常", 4: "到期", 5: "禁用"
            },
            //关联进驻分类设置
            showEnterType: false,
            associationTitle: "",
            typeData: [],
            typeDataLoading: false,
            //导入配置
            showImportModel: false,
            importTypeData: [],
            uploadUrl: "/api/crmPc/companyGoods/importCompanyMessage",
            uploadData: {
                associationId: "",
                intoType: "",
                typeLink: ""
            },
            uploadFromData: {
                typeLink: []
            },
            uploadRules: {
                typeLink: [{ required: true, message: "请选择进驻类型", trigger: 'change' }],
            },
            headers: {
                token: localStorage.getItem("token"),
            },
            importMessage: "",
            showMessage: false,
        };
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        },
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.shopTableInfo.currentPage = 1
            this.getParkShopTypeLinkList()
            this.getParkIntoOption();
            this.shopList();
        }
    },
    created() {
        let checkedPark = sessionStorage.getItem('checkedAssociation');
        this.associationId = JSON.parse(checkedPark).associationId;
        this.shopList();
        this.getParkShopTypeLinkList()
        this.getParkIntoOption();
    },
    methods: {
        beforeUploadFun() {
            this.$message({
                type: 'info',
                message: "导入商家中,请稍后..."
            })
        },
        changeImportType(val) {
            //-console.log(process.env);
            this.uploadData = {
                associationId: this.associationId,
                intoType: this.intoType,
                typeLink: this.uploadFromData.typeLink.join(",")
            }
            if (this.uploadFromData.typeLink.length >= 3) {
                this.$message({
                    type: "warning",
                    meassage: "进驻类型不能超过3个"
                })
                this.typeLink.splice(1, this.typeLink.length)
            }
        },
        handleSuccess(res) {
            this.importMessage = res.data;
            this.showMessage = true;
            this.shopSearchFun();
        },
        /**@method 显示导入公司模态框 */
        showImportFun() {
            this.showImportModel = true;
        },
        /**@method 开启/关闭进驻分类 */
        async changeEnterTypeStatus(status, row) {
            let params = {
                status,
                typeId: row.typeId,
                associationId: this.associationId
            };
            let res = null;
            if (row.linkId) {
                params.linkId = row.linkId;
                res = await updateParkShopTypeLink(params)
            } else {
                res = await insertParkShopTypeLink(params)
            }
            if (res.code != 200) {
                this.$message({
                    message: res.message,
                    type: "error"
                })
            }
        },
        /**@method 获取分类数据 */
        async getParkShopTypeLinkList() {
            this.typeDataLoading = true;
            let res = await getParkShopTypeLinkList({
                associationId: this.associationId,
                typeUse: this.intoType
            });
            try {
                this.typeData = res.data;
            } catch (err) {
                this.typeData = [];
            }
            this.typeDataLoading = false;
        },
        /**@method 显示进驻分类列表 */
        showEnterTypeModel() {
            this.showEnterType = true;
        },
        /**@method 显示设置进驻费用表单 */
        showOptionModelFun() {
            this.showOptionModel = true;
        },
        /**@method 设置进驻费用 */
        setOptionInfo() {
            this.$refs.optionData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.optionData,
                        associationId: this.associationId,
                        optionType: this.intoType
                    };
                    setParkIntoOption(params).then(res => {
                        this.$message({
                            type: "success",
                            message: "设置完成"
                        })
                        this.getParkIntoOption();
                        this.showOptionModel = false;
                    }).catch(err => {
                        this.$message.error(err.message)
                    })
                }
            })
        },
        /**@method 获取设置信息 */
        getParkIntoOption() {
            this.optionLoading = true;
            getParkIntoOption({ associationId: this.associationId, optionType: this.intoType }).then(res => {
                this.optionLoading = false;
                let data = res.data;
                this.optionData = {
                    intoPrice: data.intoPrice,
                    typeNum: data.typeNum,
                    auditInto:data.auditInto,

                };
                this.importTypeData = data.parkShopTypeLinkList ? data.parkShopTypeLinkList : [];
            }).catch(err => {
                this.optionLoading = false;
            })
        },
        /**@method 显示禁用原因表单 */
        showReasonModelFun(intoId) {
            this.updateId = intoId;
            this.showReasonModel = true;
        },
        /**@method 显示图片 */
        showImgModel(title, url) {
            this.showImgTitle = title;
            this.showImg = true;
            this.imgUrlArr = url.split(",");
        },
        /**@method 切换分类加载列表 */
        tabClickFun(val) {
            this.shopList();
        },
        /**@method 审核 */
        examineFun(status, intoId) {
            let params = {
                status,
                intoId: intoId,
            };
            if (status == 5) {
                if (!this.reason) {
                    this.$message({
                        message: "请输入禁用原因",
                        type: "warning"
                    })
                    return;
                }
                params.remark = this.reason;
            }
            updateParkIntoCompany(params).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: "完成",
                        type: "success"
                    })
                    this.showShopApplyModel = false;
                    this.showReasonModel = false;
                    this.shopSearchFun();
                } else {
                    this.$message({
                        message: res.message,
                        type: "error"
                    })
                }
            }).catch(err => {
                this.$message({
                    message: err.message,
                    type: "error"
                })
            })
        },
        /**@method 显示审核内容
         * @param {Object} row - 当前点击行的值
         */
        showDetailsModelFun(row) {
            this.showShopApplyModel = true;
            this.shopDetailsLoading = true;
            getParkIntoCompany({ intoId: row.intoId }).then(res => {
                this.shopDetailsLoading = false;
                if (res.data) {
                    let data = res.data;
                    this.shopApplyDetails = {
                        companyName: data.companyName,
                        name: data.name,
                        phone: data.phone,
                        typeList: data.typeList,
                        startTime: data.startTime ? data.startTime.slice(0, 10) + " " + row.startTime.slice(11, 19) : "",
                        endTime: data.endTime ? data.endTime.slice(0, 10) + " " + row.startTime.slice(11, 19) : ""
                    };


                }
            })
        },
        /**@method 列表API设置 */
        /**@method 搜索 */
        shopSearchFun() {
            this.shopTableInfo.currentPage = 1;
            this.shopList();
        },
        /**@method 切换行 */
        shopSizeChange(val) {
            this.shopTableInfo.pageSize = val;
            this.shopList();
        },
        /**@method 切换下一页 */
        shopCurrentChange(val) {
            this.shopTableInfo.currentPage = val;
            this.shopList();
        },
        async shopList() {
            let params = {
                intoType: this.intoType,
                status: this.shopTableInfo.status,
                associationId: this.associationId,
                pageNum: this.shopTableInfo.currentPage,
                pageSize: this.shopTableInfo.pageSize
            };
            try {
                this.shopTableInfo.loading = true;
                let res = await getParkIntoCompanyList(params);
                this.shopTableInfo.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    if (row.typeList && row.typeList.length > 0) {
                        let typeTitle = [];
                        for (let type of row.typeList) {
                            typeTitle.push(type.typeName)
                        }
                        row.typeTitle = typeTitle.join("、")
                    }
                    if (row.startTime) {
                        row.startTime = row.startTime.slice(0, 10) + " " + row.startTime.slice(11, 19)
                    }
                    if (row.endTime) {
                        row.endTime = row.endTime.slice(0, 10) + " " + row.endTime.slice(11, 19)
                    }
                    tableData.push(row);
                }
                this.shopTableInfo.tableData = tableData;
                this.shopTableInfo.total = data.pageInfo.total;
            } catch (error) {
                //-console.log(error);
                this.shopTableInfo.loading = false;
                this.shopTableInfo.tableData = [];
            }
        },
    },
};
</script>
<style>
.importLoad .el-upload--text {
    background-color: #fff;
    border: none;
    border-radius: 6px;
    box-sizing: border-box;
    width: auto;
    height: auto;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
</style>
<style lang="scss" scoped>
.logo {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.myCardView {
    max-height: 178px;
    padding-bottom: 10px;
    overflow-y: auto;
    border: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
}

.myCardItem {
    width: 230px;
    margin: 10px;
    margin-bottom: 0px;
}

.leftImg {
    font-size: 26px;
    width: 60px;
    height: 60px;
    color: #fff;

    img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }
}

.rightText {
    font-size: 15px;
    margin-left: 10px;
    padding-top: 5px;
    position: relative;
    width: 130px;

    .pName {
        line-height: 20px;
    }

    .pPrice {
        .i {
            font-size: 13px;
        }

        position:absolute;
        bottom:0;
        color:#e03436;
    }
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>